//global.websiteUrl="https://app.medtech-gurus.com"
global.CopyRightText="© 2023 Cetas Medtech Gurus. - ISO 9001 Certified"
global.webUrl="https://www.cetas.medtech-gurus.com"

//global.websiteUrl="https://www.cetas.medtech-gurus.com/Cetas"
//global.websiteUrl="https://www.tech.medtech-gurus.com/Cetas"
global.websiteUrl="https://app.medtech-gurus.com/Cetas"

 //global.websiteUrl="http://localhost/Cetas"


